@import '../../styles/variables';
@import '../../styles/mixins';

.header {
    padding: 20px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba($background-color, 0.6);
    backdrop-filter: blur(20px);
    z-index: 1001;

    &.menu-open {
        background-color: transparent;
        backdrop-filter: none;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        img {
            height: 30px;
        }
    }

    &__nav {
        display: flex;
        align-items: center;

        &--desktop {
            @media (max-width: $breakpoint-md) {
                display: none;
            }
        }

        ul {
            display: flex;
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                margin: 0 15px;

                a {
                    color: $text-color;
                    text-decoration: none;
                    font-weight: 500;
                    transition: color 0.3s ease;

                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }
    }

    &__cta {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-color;
        color: $background-color;
        border: none;
        padding: 10px 20px;
        border-radius: 50px;
        cursor: pointer;
        font-weight: bold;
        transition: all 0.3s ease;
        margin-left: 20px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        &-icon {
            margin-right: 8px;
            font-size: 1.2em;
        }

        &:hover {
            background-color: darken($primary-color, 10%);
            transform: translateY(-2px);
            box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
        }
    }

    &__hamburger {
        display: none;
        background: none;
        border: none;
        cursor: pointer;
        padding: 10px;
        z-index: 1001;

        @media (max-width: $breakpoint-md) {
            display: block;
        }

        span {
            display: block;
            width: 25px;
            height: 3px;
            background-color: $text-color;
            margin: 5px 0;
            transition: all 0.3s ease;
        }

        &.is-active {
            span:nth-child(1) {
                transform: rotate(45deg) translate(5px, 5px);
            }
            span:nth-child(2) {
                opacity: 0;
            }
            span:nth-child(3) {
                transform: rotate(-45deg) translate(7px, -6px);
            }
        }
    }
}

.full-screen-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($background-color, 0.7);
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;

    &.is-open {
        opacity: 1;
        visibility: visible;
    }

    &__content {
        text-align: center;

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0 0 30px;

            li {
                margin: 20px 0;

                a {
                    color: $text-color;
                    text-decoration: none;
                    font-weight: 500;
                    font-size: 1.5rem;
                    transition: color 0.3s ease;

                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }

        .header__cta {
            margin-left: 0;
        }
    }
}

@media (min-width: $breakpoint-md) {
    // .full-screen-nav {
    //     display: none;
    // }
}