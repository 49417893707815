// Colors
$primary-color: #00E676;
$background-color: #13151A;
$text-color: #FFFFFF;
$secondary-color: #333333;

// Fonts
$font-family: 'Arial', sans-serif;

// Breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;