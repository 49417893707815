@import '../../styles/variables';
@import '../../styles/mixins';

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $background-color;
  @include flex-center;
  z-index: 9999;
}

.loader {
  width: 50px;
  height: 50px;
  border: 5px solid $secondary-color;
  border-top: 5px solid $primary-color;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}