@import '../../styles/variables';
@import '../../styles/mixins';


// $background-color: #1a1a1a !default;
// $text-color: #ffffff !default;
// $primary-color: #00E676 !default;

.hero {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 20px;
    text-align: center;
    min-height: 84vh;
    overflow: hidden;
    background-color: $background-color;

    #particles-js {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .container {
        position: relative;
        z-index: 2;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
    }

    &__title, &__subtitle, &__description {
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    &__title {
        font-size: clamp(2rem, 5vw, 3rem);
        margin-bottom: 20px;
        color: $text-color;

        &--highlight {
            color: $primary-color;
        }
    }

    &__subtitle {
        font-size: clamp(1.2rem, 3vw, 1.5rem);
        margin-bottom: 20px;
        color: $text-color;
    }

    &__description {
        max-width: 600px;
        margin: 0 auto 30px;
        font-size: clamp(0.9rem, 2vw, 1rem);
        line-height: 1.5;
        color: $text-color;
    }

    &__cta-group {
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    &__cta {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 24px;
        border-radius: 50px;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: clamp(0.9rem, 2vw, 1rem);
        opacity: 1;
        position: relative;
        z-index: 3;

        &-icon {
            margin-right: 8px;
            font-size: 1.2em;
        }

        &--primary {
            background-color: $primary-color;
            color: $background-color;
            border: none;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            opacity: 0;

            &:hover {
                background-color: darken($primary-color, 10%);
                transform: translateY(-2px);
                box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
            }
        }

        &--secondary {
            background-color: transparent;
            color: $text-color;
            border: 2px solid $text-color;
            opacity: 0;

            &:hover {
                background-color: rgba($text-color, 0.1);
                transform: translateY(-2px);
            }
        }
    }
}

@media (max-width: $breakpoint-md) {
    .hero {
        padding: 60px 20px;

        &__cta-group {
            flex-direction: column;
            align-items: center;
        }

        &__cta {
            width: 100%;
            max-width: 250px;
            margin-bottom: 10px;
        }
    }
}